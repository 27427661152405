@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSansSemibold';
  src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSansBold';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
}
